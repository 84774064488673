import styled from 'styled-components';
import Img from 'gatsby-image';
import IndexLayout from '../layouts';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import { AppTitle, NavBar, TextCycler } from '../components/ui';
import { BottomNavSegment, Footer, OurPressSegment } from '../components/PageSegments';
import favicon from '../content/img/icon/favicon.ico';

export default () => {
  return (
    <IndexLayout>
      <Helmet>
        <title>Press - Base10</title>
        <link rel="stylesheet" type="text/css" href="/global.css" />
        <link rel="icon" href={favicon} />
        <meta name="description" content="Investing in Automation for the Real Economy" />
        <meta charSet="UTF-8" />
        <html lang="en-US" />
        <meta name="theme-color" content="#1a1a1a" />
        <link rel="canonical" href="https://base10.vc/content/" />
      </Helmet>
      <Main>
        <NavBar invert anchorToHere />
        <OurPressSegment />
        <Footer />
        <BottomNavSegment />
      </Main>
    </IndexLayout>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* height: 2000px; */
  /* background: grey; */
`;
